import React, { useState, useContext, useEffect } from 'react'
import clsx from 'clsx'

import {
  makeStyles,
  Drawer,
  AppBar,
  Toolbar,
  List,
  Typography,
  Divider,
  MenuItem,
  IconButton,
  Menu,
  Button,
} from '@material-ui/core'

import MenuIcon from '@material-ui/icons/Menu'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import AccountCircle from '@material-ui/icons/AccountCircle'

import MainListItems from './MainListItems'
import NotificationsPopOver from '../components/NotificationsPopOver'
import UserModal from '../components/UserModal'
import { AuthContext } from '../context/Auth/AuthContext'
import BackdropLoading from '../components/BackdropLoading'
import { i18n } from '../translate/i18n'
import api from '../services/api'
import toastError from '../errors/toastError'
import { SearchRounded } from '@material-ui/icons'
import FastTicketSearch from '../components/FastTicketSearch'
import UnfinishedTicketSearch from '../components/UnfinishedTicketSearch'

const drawerWidth = 240

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    height: '100vh',
    [theme.breakpoints.down('sm')]: {
      height: 'calc(100vh - 56px)',
    },
  },

  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    minHeight: '48px',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: 'none',
  },
  title: {
    flexGrow: 1,
  },
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9),
    },
  },
  appBarSpacer: {
    minHeight: '48px',
  },
  content: {
    flex: 1,
    overflow: 'auto',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  userStatus: {
    borderRadius: 2,
    height: 24,
    margin: 4,
  },
  online: {
    backgroundColor: '#229A00',
  },
  offline: {
    backgroundColor: '#FF0000',
  },
  searchButton: {
    borderRadius: 2,
    height: 24,
    margin: 4,
    backgroundColor: '#064f9e',
  },
  searchTermModalButton: {
    borderRadius: 2,
    height: 24,
    margin: 4,
    backgroundColor: '#009e90',
  }
}))

const LoggedInLayout = ({ children }) => {
  const classes = useStyles()
  const [userModalOpen, setUserModalOpen] = useState(false)
  const [ticketSearchModalOpen, setTicketSearchModalOpen] = useState(false)
  const [unfinishedTicketSearchModalOpen, setUnfinishedTicketSearchModalOpen] = useState(false)
  const [anchorEl, setAnchorEl] = useState(null)
  const [menuOpen, setMenuOpen] = useState(false)
  const { handleLogout, loading } = useContext(AuthContext)
  const [drawerOpen, setDrawerOpen] = useState(false)
  const [drawerVariant, setDrawerVariant] = useState('permanent')
  const { user, setUser } = useContext(AuthContext)

  useEffect(() => {
    if (document.body.offsetWidth > 600) {
      setDrawerOpen(true)
    }
  }, [])

  useEffect(() => {
    if (document.body.offsetWidth < 600) {
      setDrawerVariant('temporary')
    } else {
      setDrawerVariant('permanent')
    }
  }, [drawerOpen])

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget)
    setMenuOpen(true)
  }

  const handleCloseMenu = () => {
    setAnchorEl(null)
    setMenuOpen(false)
  }

  const handleOpenUserModal = () => {
    setUserModalOpen(true)
    handleCloseMenu()
  }

  const handleClickLogout = () => {
    handleCloseMenu()
    handleLogout()
  }

  const drawerClose = () => {
    if (document.body.offsetWidth < 600) {
      setDrawerOpen(false)
    }
  }

  const updateUserStatus = async (currentStatus) => {
    try {
      const { status, data } = await api.put(`/users/status/${currentStatus}`)

      if (status === 200) {
        setUser(data.user)
      }
    } catch (err) {
      toastError(err)
    }
  }

  if (loading) {
    return <BackdropLoading />
  }

  return (
    <div className={classes.root}>
      <Drawer
        variant={drawerVariant}
        className={drawerOpen ? classes.drawerPaper : classes.drawerPaperClose}
        classes={{
          paper: clsx(classes.drawerPaper, !drawerOpen && classes.drawerPaperClose),
        }}
        open={drawerOpen}
      >
        <div className={classes.toolbarIcon}>
          <IconButton onClick={() => setDrawerOpen(!drawerOpen)}>
            <ChevronLeftIcon />
          </IconButton>
        </div>
        <Divider />
        <List>
          <MainListItems drawerClose={drawerClose} />
        </List>
        <Divider />
      </Drawer>
      <UserModal open={userModalOpen} onClose={() => setUserModalOpen(false)} userId={user?.id} />
      <FastTicketSearch open={ticketSearchModalOpen} onClose={() => setTicketSearchModalOpen(false)} />
      <UnfinishedTicketSearch open={unfinishedTicketSearchModalOpen} onClose={() => setUnfinishedTicketSearchModalOpen(false)} />
      <AppBar
        position="absolute"
        className={clsx(classes.appBar, drawerOpen && classes.appBarShift)}
        color={process.env.NODE_ENV === 'development' ? 'inherit' : 'primary'}
      >
        <Toolbar variant="dense" className={classes.toolbar}>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={() => setDrawerOpen(!drawerOpen)}
            className={clsx(classes.menuButton, drawerOpen && classes.menuButtonHidden)}
          >
            <MenuIcon />
          </IconButton>
          <Typography component="h1" variant="h6" color="inherit" noWrap className={classes.title}>
            WhaTicket
          </Typography>

          <Button
            onClick={() => { }}
            className={clsx(
              classes.searchTermModalButton
            )}
          >
            <Typography
              component="h5"
              variant="h7"
              color="inherit"
              noWrap
              className={classes.title}
              style={{ color: '#fff' }}
              onClick={() => setUnfinishedTicketSearchModalOpen(true)}
            >
              BUSCAR PENDENTES
            </Typography>
          </Button>

          <Button
            onClick={() => { }}
            className={clsx(
              classes.searchButton
            )}
          >
            <Typography
              component="h5"
              variant="h7"
              color="inherit"
              noWrap
              className={classes.title}
              style={{ color: '#fff' }}
              onClick={() => setTicketSearchModalOpen(true)}
            >
              BUSCAR PROTOCOLO
            </Typography>
          </Button>

          <Button
            onClick={() => updateUserStatus(user.status === 'online' ? 'offline' : 'online')}
            className={clsx(
              classes.userStatus,
              user.status === 'online' ? classes.online : classes.offline
            )}
          >
            <Typography
              component="h5"
              variant="h7"
              color="inherit"
              noWrap
              className={classes.title}
              style={{ color: '#fff' }}
            >
              {user.status === 'online' ? 'ONLINE' : 'OFFLINE'}
            </Typography>
          </Button>

          {user.id && <NotificationsPopOver />}
          <div>
            <IconButton
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleMenu}
              color="inherit"
            >
              <AccountCircle />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              getContentAnchorEl={null}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={menuOpen}
              onClose={handleCloseMenu}
            >
              <MenuItem onClick={handleOpenUserModal}>
                {i18n.t('mainDrawer.appBar.user.profile')}
              </MenuItem>
              <MenuItem onClick={handleClickLogout}>
                {i18n.t('mainDrawer.appBar.user.logout')}
              </MenuItem>
            </Menu>
          </div>
        </Toolbar>
      </AppBar>
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />

        {children ? children : null}
      </main>
    </div>
  )
}

export default LoggedInLayout
