import React, { useState } from "react";

import { Formik, Form, Field } from "formik";

import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	CircularProgress,
	TextField,
} from '@material-ui/core';

import { makeStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";

import { i18n } from "../../translate/i18n";

import toastError from "../../errors/toastError";
import api from "../../services/api";

const useStyles = makeStyles(theme => ({
	root: {
		display: "flex",
		flexWrap: "wrap",
	},
	multFieldLine: {
		display: "flex",
		"& > *:not(:last-child)": {
			marginRight: theme.spacing(1),
		},
	},

	btnWrapper: {
		position: "relative",
	},

	buttonProgress: {
		color: green[500],
		position: "absolute",
		top: "50%",
		left: "50%",
		marginTop: -12,
		marginLeft: -12,
	},
	formControl: {
		margin: theme.spacing(1),
		minWidth: 120,
	},
}));

const FastTicketSearch = ({ open, onClose }) => {
	const classes = useStyles();

	const initialState = {
		protocol: ""
	};

	const [params, setParams] = useState(initialState);

	const handleClose = () => {
		onClose();
		setParams(initialState)
	};

	const handleTicketSearch = async values => {
		const ticketId = Number(values.protocol.substring(6))
		try {
			if (await ticketExists(ticketId)) {
				window.location.href = `/tickets/${ticketId}`
			}
		} catch (err) {
			toastError(err);
		}
	};

	const ticketExists = async (ticketId) => {
		try {
			const { data } = await api.get(`/tickets/${ticketId}`)
			return data.id ? true : false
		} catch (err) {
			toastError(err);
		}
	}

	return (
		<div className={classes.root}>
			<Dialog
				open={open}
				onClose={handleClose}
				maxWidth="xs"
				fullWidth
				scroll="paper"
			>
				<DialogTitle id="form-dialog-title">
					Pesquisa de ticket rápida
				</DialogTitle>
				<Formik
					initialValues={params}
					enableReinitialize={true}
					onSubmit={(values, actions) => {
						setTimeout(() => {
							handleTicketSearch(values);
							actions.setSubmitting(false);
						}, 400);
					}}
				>
					{({ touched, errors, isSubmitting }) => (
						<Form>
							<DialogContent dividers>
								<Field
									as={TextField}
									label="Protocolo"
									autoFocus
									name="protocol"
									variant="outlined"
									margin="dense"
									fullWidth
								/>
							</DialogContent>
							<DialogActions>
								<Button
									onClick={handleClose}
									color="secondary"
									disabled={isSubmitting}
									variant="outlined"
								>
									{i18n.t("userModal.buttons.cancel")}
								</Button>
								<Button
									type="submit"
									color="primary"
									disabled={isSubmitting}
									variant="contained"
									className={classes.btnWrapper}
								>
									Pesquisar
									{isSubmitting && (
										<CircularProgress
											size={24}
											className={classes.buttonProgress}
										/>
									)}
								</Button>
							</DialogActions>
						</Form>
					)}
				</Formik>
			</Dialog>
		</div>
	);
};

export default FastTicketSearch;
